module.exports = {
  siteTitle: 'Ency Consulting s.r.o.', // <title>
  siteTitleLong: 'Ency Consulting s.r.o. - Zabezpečovací systémy a Informační technologie',
  manifestName: 'Grayscale',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  //manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-grayscale/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Ency Consulting',
  subHeading: 'Zabezpečení a informační technologie pro Vás.',


};
